import React, { Fragment } from 'react';
import SectionAuthorMaybe from '../../ListingPage/SectionAuthorMaybe';
import SectionDetailsMaybe from '../../ListingPage/SectionDetailsMaybe';
import SectionMapMaybe from '../../ListingPage/SectionMapMaybe';
import SectionReviews from '../../ListingPage/SectionReviews';

import { propTypes } from '../../../util/types';
import { shape, object, func, arrayOf } from 'prop-types';
import { intlShape } from '../../../util/reactIntl';
import { denormalisedEntities, ensureListing } from '../../../util/data';

import css from './SearchPageListingPreview.module.css';

const SearchPageListingPreview = props => {
  const {
    currentUser,
    openContactModal,
    intl,
    listing,
    config,
    history,
    reviews,
    contactInfo,
  } = props;

  if (!listing) return <p>{intl.formatMessage({ id: 'SearchPage.selectListing' })}</p>;

  const currentListing = ensureListing(listing);

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const authorAvailable = currentListing?.author;
  const currentAuthor = authorAvailable ? currentListing.author : null;

  return (
    <div className={css.root}>
      <SectionAuthorMaybe
        author={currentAuthor}
        currentUser={currentUser}
        history={history}
        location={publicData?.location}
        contactInfo={contactInfo}
        openContactModal={() => openContactModal(currentListing)}
      />
      <SectionDetailsMaybe
        description={description}
        publicData={publicData}
        metadata={metadata}
        listingConfig={config.listing}
        intl={intl}
      />
      <SectionMapMaybe
        geolocation={geolocation}
        publicData={publicData}
        listingId={currentListing.id}
        mapsConfig={config.maps}
      />
      <SectionReviews reviews={reviews} fetchReviewsError={null} />
    </div>
  );
};

SearchPageListingPreview.propTypes = {
  currentUser: propTypes.currentUser,
  openContactModal: func,
  intl: intlShape.isRequired,
  listing: propTypes.listing,
  config: object.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  reviews: arrayOf(propTypes.review),
};

export default SearchPageListingPreview;
